<template>
  <div class="container">
    <Header />
    <div class="wrapper">
      <div class="carousel">
        <el-carousel trigger="click" interv="5000">
          <el-carousel-item :key="1">
            <div class="banner mirrors">
              <div class="banner-content">
                <div class="product-name">
                  <span>移动云开源镜像站</span>
                </div>
                <div class="product-description">
                  <span>
                    移动云开源镜像站，包含BC-Linux各版本的软件仓库，对外提供软件升级服务。
                  另外也提供多种类别的软件安装源和ISO下载服务，包含CentOS、Ubuntu等主流发行版的软件源镜像。
                  为移动云用户提供免费高速的一站式镜像服务。
                  </span>
                </div>
              </div>
           </div>
          </el-carousel-item>
          <el-carousel-item :key="2">
            <div class="banner linux">
              <div class="banner-content">
                <div class="product-name">
                  <span>Linux系统订阅服务 CLS</span>
                </div>
                <div class="product-description">
                  <span>
                    Linux系统订阅服务 CLS（Cloud Linux Service）
                    为使用BC-Linux操作系统的用户提供高质量商业化的系统订阅，BC-Linux是以开源社区Linux操作系统为基础，
                    通过定制化手段研发的企业级Linux操作系统。
                    购买Linux系统订阅服务的用户可在第一时间获得BC-Linux操作系统的补丁、漏洞修复、更新、升级的推送，
                    让您的系统安全、可靠的运行。
                  </span>
                </div>
                <div class="product-button">
                  <el-button type="primary" @click="toIntroduction('linux')">了解详情</el-button>
                </div>
              </div>
           </div>
          </el-carousel-item>
          <el-carousel-item :key="3">
            <div class="banner container">
              <div class="banner-content">
                <div class="product-name">
                  <span>容器镜像服务 CIS</span>
                </div>
                <div class="product-description">
                  <span>
                    容器镜像服务 CIS（Container Image Service）
                    是一种简单易用、安全可靠的镜像管理服务，提供容器镜像的全生命周期管理，
                    方便用户拉取、推送、删除镜像，并且提供大量移动云镜像、DockerHub镜像和用户公开镜像，
                    方便用户使用。
                  </span>
                </div>
                <div class="product-button">
                  <el-button type="primary" @click="toIntroduction('container')">了解详情</el-button>
                </div>
              </div>
           </div>
          </el-carousel-item>
          <el-carousel-item :key="4">
            <div class="banner rocketMQ">
              <div class="banner-content">
                <div class="product-name">
                  <span>消息队列 RocketMQ</span>
                </div>
                <div class="product-description">
                  <span>
                    基于分布式集群技术，为用户提供安全可靠、简单易用、低延迟、
                    高可用的消息队列服务，可用于分布式部署的不同应用间消息存储与收发。
                  </span>
                </div>
                <div class="product-button">
                  <el-button type="primary" @click="toIntroduction('rocketMQ')">了解详情</el-button>
                </div>
              </div>
           </div>
          </el-carousel-item>
          <el-carousel-item :key="5">
            <div class="banner developer">
              <div class="banner-content">
                <div class="product-name">
                  <span>移动云开发者社区</span>
                </div>
                <div class="product-description">
                  <span>
                    移动云开发者社区为广大开发者用户提供技术交流和能力输出的平台，
                    是移动云开发者交流汇聚地、移动云产品首席体验官工作台、
                    移动云技术能力布道者讲台和移动云能力输出窗口。
                  </span>
                </div>
                <div class="product-button">
                  <el-button type="primary" @click="toIntroduction('developer')">了解详情</el-button>
                </div>
              </div>
           </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="menu">
        <el-menu :default-active="activeIndex" mode="horizontal" @select="handleMenu">
          <el-menu-item index="1">镜像</el-menu-item>
          <el-menu-item index="2">OS镜像</el-menu-item>
        </el-menu>
      </div>
      <div v-if="showContent === 1" class="content">
        <List />
      </div>
      <div v-if="showContent === 2" class="content">
        <InstallOption />
      </div>
    </div>
    <Footer />
    <el-backtop :bottom="100">
    <div
      style="
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #337DFF;
      "
    >
      UP
    </div>
    </el-backtop>
  </div>
</template>

<script>
import Header from '@/components/header';
import Footer from '@/components/footer';
import List from '@/views/list/index.vue';
import Configuration from '@/views/configuration/index.vue';
import InstallOption from '@/views/installOption/index.vue';

export default {
  name: 'Mirrors',
  components: {
    Header,
    Footer,
    List,
    Configuration,
    InstallOption,
  },
  data() {
    return {
      activeIndex: '1',
      showContent: 1,
      visible: false,
      title: '公告',
    };
  },
  computed: {
  },
  methods: {
    handleMenu(key) {
      this.showContent = parseInt(key);
    },
    toIntroduction(name){
      if(name === 'linux') {        
        window.open(`https://ecloud.10086.cn/home/product-introduction/cls`,'_blank');
      } else if(name === 'container') {        
        window.open(`https://ecloud.10086.cn/home/product-introduction/caasimage`,'_blank');
      } else if(name === 'rocketMQ') {
        window.open(`https://ecloud.10086.cn/home/product-introduction/rocketmq`,'_blank');
      } else if(name === 'developer') {       
        window.open(`https://ecloud.10086.cn/api/query/developer/user/home.html`,'_blank');
      }
    }
  },
  mounted () {
    this.visible = true;
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .wrapper {
    min-height: calc(100% - 130px);
    .carousel {
      height: 400px;
      .banner {
        width:100%;
        height:100%;
        background-size: cover;
        .banner-content {
          width: 650px;
          padding-top: 100px;
          padding-left:calc(15%);
          .product-name{
            font-size: 36px;
            font-weight: 400;
            color: #333333;
            line-height: 54px;
          }
          .product-description{
            margin-top: 10px;
            margin-bottom: 30px;
            font-size: 14px;
            font-weight: 400;
            color: #555555;
            line-height: 22px;
          }
          .product-button{
            ::v-deep .el-button{
              width: 110px !important;
              border-radius: 2px;
            }
          }
        }
      }
      .mirrors{
        background-image: url("../assets/images/banner.jpg");
        background-repeat: no-repeat;
        background-position: center;
        .banner-content{
          .product-name, .product-description{
            color: #fff;
          }
        }
      }
      .linux{
        background-image: url("../assets/images/linux.jpg");
        background-repeat: no-repeat;
        background-position: center;
        .banner-content{
          .product-name, .product-description{
            color: #fff;
          }
        }
      }
      .container{
        background-image: url("../assets/images/container.jpg");
        background-repeat: no-repeat;
        background-position: center;
        .banner-content{
          .product-name, .product-description{
            color: #fff;
          }
        }
      }
      .rocketMQ{
        background-image: url("../assets/images/rocketMQ.jpg");
        background-repeat: no-repeat;
        background-position: center;
        .banner-content{
          .product-name, .product-description{
            color: #fff;
          }
        }
      }
      .developer{
        background-image: url("../assets/images/developer.jpg");
        background-repeat: no-repeat;
        background-position: center;
        .banner-content{
          .product-name, .product-description{
            color: #fff;
          }
        }
      }
    }
    .menu, .content {
      width:1200px;
      margin: 30px auto 50px;
    }
  }
  .affiche_time{
    margin-right: 10px;
  }
}
</style>
