<template>
    <div class="content">
        <div class="config-description">
          <span>
            为便于用户部署指定的发行版，中国移动苏研 BC-Linux 开源镜像网站提供一套源列表自动生成工具。
            请于下拉框中选择您需要的 Linux 发行版及版本，点击生成按钮，将自动生成。
            苏研提供的源列表配置说明：
          </span>
        </div>
        <div class="config-form">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                <el-form-item label="发行版:" prop="distribution">
                    <el-select
                      v-model="ruleForm.distribution"
                      placeholder="请选择" 
                      @change="changeDistribution"
                    >
                      <el-option
                        v-for="item in distribution"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="版本:" prop="version" class="config-version">
                    <el-select v-model="ruleForm.version" placeholder="请选择">
                        <el-option
                          v-for="item in version"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="config-button">
                  <el-button type="primary" @click="submitForm('ruleForm')">生成</el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="config-content">
          <el-alert
            title="请删除/etc/yum.repos.d中的所有文件，然后在/etc/yum.repos.d目录中建立BCLinux.repo文件，复制以下内容并填入。"
            type="info"
            show-icon
            :closable="false">
          </el-alert>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Configuration',
  data() {
    return {
      ruleForm: {
        distribution: '',
        version: '',
      },
      rules: {
        distribution: [
          { required: true, message: '请选择发行版', trigger: 'change' }
        ],
        version: [
          { required: true, message: '请选择版本', trigger: 'change' }
        ],
      },
      distribution:[],
      version:[],
    };
  },
  computed: {
    ...mapState([ 'configList' ]),
  },
  methods: {
    ...mapActions([ 'getConfigList' ]),
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.version = [];
    },
    changeDistribution(value){
      this.configList[value].forEach((item) => {
        this.version.push({
          label:item.name,
          value:item.name,
        })
      });
    }
  },
  mounted () {
    this.getConfigList().then(() => {
      Object.keys(this.configList).forEach((item) => {
        this.distribution.push({
          label:item,
          value:item,
        })
      })
    });
  },
}
</script>

<style lang="scss" scoped>
.content { 
    min-height: 340px;
        .config-description{
          width: 1200px;
          font-size: 18px;
          font-weight: 400;
          color: #333;
          line-height: 27px;
        }
        .config-form{
            margin-top: 30px;
        }
        .config-version{
            margin-left:30px
        }
        .config-button{
            ::v-deep .el-form-item__content{
                margin-left: 15px !important;
            }
        }
}
</style>
