<template>
      <el-dialog
        :title="mirrorsDetail.title"
        :visible.sync="dialogVisible"
        :before-close="closeDialog">
        <div>
            <span>{{ mirrorsUrl.text }}</span><a :href="mirrorsUrl.url" target="blank">{{ mirrorsUrl.url }}</a>
        </div>
        <div class="bold">配置方法</div>
        <div v-for="(item , i) in config" :key="i">
            <div v-if="item.version" class="bold">{{ item.version }}</div>
            <div v-for="(itemX, j) in item.detail" :key="j">
                <div class="step-pre">{{ itemX.step }}</div>
                <div v-for="(itemY, k) in itemX.operation" :key="k">
                  <pre>{{ itemY }}</pre>
                </div>
            </div>
        </div>
        <div class="bold">相关网址</div>
        <div v-for="(itemZ, l) in relatedUrl" :key=" 1000 + l">
            <span>{{ itemZ.text }}</span><a :href="itemZ.url" target="blank">{{ itemZ.url }}</a>
        </div>
      </el-dialog>
</template>

<script>
export default {
  name: 'ToolDialog',
  props: {
    mirrorsDetail: {
        type: Object,
        default: () => {}
    },
    dialogVisible: {
        type: Boolean,
        default: false
    },
  },
  data() {
    return {
        title: '',
        mirrorsUrl: {},
        config: [],
        relatedUrl: []
    }
  },
  methods: {
    closeDialog() {
       this.$emit('changeVisible') 
    }
  },
  mounted () {
    this.title = this.mirrorsDetail.title;
    this.mirrorsUrl = this.mirrorsDetail.mirrorsUrl;
    this.config = this.mirrorsDetail.config;
    this.relatedUrl = this.mirrorsDetail.relatedUrl;
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  border-bottom: 1px solid #e3e5e9;
  .el-dialog__title {
    font-weight: 700;
  }
}
::v-deep .el-dialog__body{
  overflow-y:auto;
  height: 600px;
}
div {
  padding: 10px;
}
.bold {
  font-weight: 700;
  border-bottom: 1px solid #e3e5e9;
}
pre {
    overflow: auto;
    background-color: #f6f8fa;
    border-radius: 3px;
    padding: 8px 16px;
    line-height: 20px;
}
.step-pre{
  white-space: pre-wrap;
}
</style>
