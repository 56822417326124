<template>
    <div class="content">
        <div class="install-description">
          <span>
            这里为您提供开源社区各大主流 Linux 发行版的安装镜像(iso)文件。请根据您需要的 Linux 发行版及其详细版本进行选择：
          </span>
        </div>
        <div class="install-form">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                <el-form-item label="发行版:" prop="distribution">
                    <el-select
                      v-model="ruleForm.distribution"
                      placeholder="请选择"
                      @change="changeDistribution"
                    >
                        <el-option
                          v-for="item in distribution"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item 
                  label="版本:"
                  prop="version"
                  class="install-version"
                  ref="version"
                >
                    <el-select
                      v-model="ruleForm.version"
                      placeholder="请选择"
                      @change="changeVersion"
                    >
                        <el-option
                          v-for="item in version"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="install-button">
                  <el-button type="primary" @click="submitForm('ruleForm')">下载</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'InstallOption',
  data() {
    return {
      ruleForm: {
        distribution: '',
        version: '',
      },
      rules: {
        distribution: [
          { required: true, message: '请选择发行版', trigger: 'change' }
        ],
        version: [
          { required: true, message: '请选择版本', trigger: 'change' }
        ],
      },
      distribution:[],
      version:[],
      url:'',
    };
  },
  computed: {
    ...mapState([ 'installList' ]),
  },
  methods: {
    ...mapActions([ 'getInstallList' ]),
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.url) {
            window.location.href=this.url;
          };
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.version = [];
    },
    changeDistribution(value){
      this.version = [];
      this.$refs['version'].resetField();
      this.installList[value].forEach((item) => {
        this.version.push({
          label:item.name,
          value:item.name,
          url:item.url
        })
      });
    },
    changeVersion(value){
      this.version.forEach((item) => {
        if(item.value === value) {
          this.url = item.url;
        }
      })
    }
  },
  mounted () {
    this.getInstallList().then(() => {
      Object.keys(this.installList).forEach((item) => {
        this.distribution.push({
          label:item,
          value:item,
        })
      })
    });
  },
}
</script>

<style lang="scss" scoped>
.content { 
    min-height: 340px;
        .install-description{
          width: 1200px;
          font-size: 18px;
          font-weight: 400;
          color: #333;
          line-height: 27px;
        }
        .install-form{
            margin-top: 30px;
        }
        .install-version{
            margin-left:30px
        }
        .install-button{
            ::v-deep .el-form-item__content{
                margin-left: 15px !important;
            }
        }
}
</style>
