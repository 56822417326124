<template>
    <div class="content">
      <el-radio-group v-model="radio">
        <el-radio-button label="all">全部</el-radio-button>
        <el-radio-button label="sys">系统</el-radio-button>
        <el-radio-button label="self">移动自研</el-radio-button>
        <el-radio-button label="language">语言</el-radio-button>
        <el-radio-button label="tool">工具</el-radio-button>
      </el-radio-group>
      <div v-if="radio === 'all'">
        <el-row v-for="i in rowAllNum" :key="i" :gutter="20">
          <el-col 
            :span="6" 
            v-for="j in ((showAllList.length - i * 4) >= 0 ? 4 : (showAllList.length % 4))" 
            :key="j"
          >
            <el-card
              :body-style="{ 'height': '75px', 'border-radius': '2px', 'position': 'relative'}"
              shadow="always"
              @click.native="toChild(showAllList[ (i - 1) * 4 + j - 1].name,
                showAllList[ (i - 1) * 4 + j - 1].category)"
            >
              <img
                v-if="showAllList[ (i - 1) * 4 + j - 1].category === 'sys'"
                class="img-logo"
                src="@/assets/images/logo.png"
              />
              <p
                v-if="showAllList[ (i - 1) * 4 + j - 1].category === 'sys'"
                class="rotate-font"
              >
              系统
              </p>
              <img
                v-if="showAllList[ (i - 1) * 4 + j - 1].category === 'self'"
                class="img-logo"
                src="@/assets/images/logo.png"
              />
              <p
                v-if="showAllList[ (i - 1) * 4 + j - 1].category === 'self'" 
                class="rotate-font"
              >
              移动
              </p>
              <img
                v-if="showAllList[ (i - 1) * 4 + j - 1].category === 'language'"
                class="img-logo"
                src="@/assets/images/logo.png"
              />
              <p
                v-if="showAllList[ (i - 1) * 4 + j - 1].category === 'language'"
                class="rotate-font"
              >
              语言
              </p>
              <img
                v-if="showAllList[ (i - 1) * 4 + j - 1].category === 'tool'"
                class="img-logo"
                src="@/assets/images/logo.png"
              />
              <p
                v-if="showAllList[ (i - 1) * 4 + j - 1].category === 'tool'"
                class="rotate-font"
              >
              工具
              </p>
              <img
                v-if="showAllList[ (i - 1) * 4 + j - 1].category === 'other'"
                class="img-logo"
                src="@/assets/images/logo.png"
              />
              <p
                v-if="showAllList[ (i - 1) * 4 + j - 1].category === 'other'"
                class="rotate-font"
              >
              其他
              </p>
              <div class="card-name">{{ showAllList[ (i - 1) * 4 + j - 1].name }}</div>
              <div :class="changeColor(showAllList[ (i - 1) * 4 + j - 1])"></div>
              <div class="card-status">{{ changeDes(showAllList[ (i - 1) * 4 + j - 1]) }}</div>
              <div class="card-update">更新：{{ changeTime(showAllList[ (i - 1) * 4 + j - 1]) }}</div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div v-if="radio === 'sys'">
        <el-row v-for="i in rowSysNum" :key="i" :gutter="20">
          <el-col 
            :span="6" 
            v-for="j in ((showSysList.length - i * 4) >= 0 ? 4 : (showSysList.length % 4))" 
            :key="j"
          >
            <el-card
              :body-style="{ 'height': '75px', 'border-radius': '2px', 'position': 'relative'}"
              shadow="always"
              @click.native="toChild(showSysList[ (i - 1) * 4 + j - 1].name, 'sys')"
            >
              <img
                class="img-logo"
                src="@/assets/images/logo.png"
              />
              <p class="rotate-font">系统</p>
              <div class="card-name">{{ showSysList[ (i - 1) * 4 + j - 1].name }}</div>
              <div :class="changeColor(showSysList[ (i - 1) * 4 + j - 1])"></div>
              <div class="card-status">{{ changeDes(showSysList[ (i - 1) * 4 + j - 1]) }}</div>
              <div class="card-update">更新：{{ changeTime(showSysList[ (i - 1) * 4 + j - 1]) }}</div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div v-if="radio === 'self'">
        <el-row v-for="i in rowSelfNum" :key="i" :gutter="20">
          <el-col 
            :span="6" 
            v-for="j in ((showSelfList.length - i * 4) >= 0 ? 4 : (showSelfList.length % 4))" 
            :key="j"
          >
            <el-card
              :body-style="{ 'height': '75px', 'border-radius': '2px', 'position': 'relative'}"
              shadow="always"
              @click.native="toChild(showSelfList[ (i - 1) * 4 + j - 1].name,
                showSelfList[ (i - 1) * 4 + j - 1].category)"
            >
              <img
                class="img-logo"
                src="@/assets/images/logo.png"
              />
              <p class="rotate-font">移动</p>
              <div class="card-name">{{ showSelfList[ (i - 1) * 4 + j - 1].name }}</div>
              <div :class="changeColor(showSelfList[ (i - 1) * 4 + j - 1])"></div>
              <div class="card-status">{{ changeDes(showSelfList[ (i - 1) * 4 + j - 1]) }}</div>
              <div class="card-update">更新：{{ changeTime(showSelfList[ (i - 1) * 4 + j - 1]) }}</div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div v-if="radio === 'language'">
        <el-row v-for="i in rowLanguageNum" :key="i" :gutter="20">
          <el-col 
            :span="6" 
            v-for="j in ((showLanguageList.length - i * 4) >= 0 ? 4 : (showLanguageList.length % 4))" 
            :key="j"
          >
            <el-card
              :body-style="{ 'height': '75px', 'border-radius': '2px', 'position': 'relative'}"
              shadow="always"
              @click.native="toChild(showLanguageList[ (i - 1) * 4 + j - 1].name, 'language')"
            >
              <img
                class="img-logo"
                src="@/assets/images/logo.png"
              />
              <p class="rotate-font">语言</p>
              <div class="card-name">{{ showLanguageList[ (i - 1) * 4 + j - 1].name }}</div>
              <div :class="changeColor(showLanguageList[ (i - 1) * 4 + j - 1])"></div>
              <div class="card-status">{{ changeDes(showLanguageList[ (i - 1) * 4 + j - 1]) }}</div>
              <div class="card-update">更新：{{ changeTime(showLanguageList[ (i - 1) * 4 + j - 1]) }}</div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div v-if="radio === 'tool'">
        <el-row v-for="i in rowToolNum" :key="i" :gutter="20">
          <el-col 
            :span="6" 
            v-for="j in ((showToolList.length - i * 4) >= 0 ? 4 : (showToolList.length % 4))" 
            :key="j"
          >
            <el-card
              :body-style="{ 'height': '75px', 'border-radius': '2px', 'position': 'relative'}"
              shadow="always"
              @click.native="toChild(showToolList[ (i - 1) * 4 + j - 1].name, 'tool')"
            >
              <img
                class="img-logo"
                src="@/assets/images/logo.png"
              />
              <p class="rotate-font">工具</p>
              <div class="card-name">{{ showToolList[ (i - 1) * 4 + j - 1].name }}</div>
              <div :class="changeColor(showToolList[ (i - 1) * 4 + j - 1])"></div>
              <div class="card-status">{{ changeDes(showToolList[ (i - 1) * 4 + j - 1]) }}</div>
              <div class="card-update">更新：{{ changeTime(showToolList[ (i - 1) * 4 + j - 1]) }}</div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <SysDialog
        v-if="dialogSysVisible"
        :mirrorsDetail= "mirrorsSysDetail"
        :dialogVisible = "dialogSysVisible"
        @changeVisible= "changeVisible('sys')"
      />
      <ToolDialog
        v-if="dialogToolVisible"
        :mirrorsDetail= "mirrorsToolDetail"
        :dialogVisible = "dialogToolVisible"
        @changeVisible= "changeVisible('tool')"
      />
      <LanguageDialog
        v-if="dialogLanguageVisible"
        :mirrorsDetail= "mirrorsLanguageDetail"
        :dialogVisible = "dialogLanguageVisible"
        @changeVisible= "changeVisible('language')"
      />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { DETAIL_LIST } from '@/utils/constant';
import SysDialog from '@/components/sysDialog';
import ToolDialog from '@/components/toolDialog';
import LanguageDialog from '@/components/languageDialog';
import bclinux from '@/help/bclinux.json';
import anolis from '@/help/anolis.json';
import openeuler from '@/help/openeuler.json';
import centos from '@/help/centos.json';
import centosVault from '@/help/centos-vault.json';
import elrepo from '@/help/elrepo.json';
import epel from '@/help/epel.json';
import fedora from '@/help/fedora.json';
import opensuse from '@/help/opensuse.json';
import rocky from '@/help/rocky.json';
import ubuntuReleases from '@/help/ubuntu-releases.json';
import ubuntu from '@/help/ubuntu.json';
import apache from '@/help/apache.json';
import ceph from '@/help/ceph.json';
import elasticstack from '@/help/elasticstack.json';
import jenkins from '@/help/jenkins.json';
import mongodb from '@/help/mongodb.json';
import mysql from '@/help/mysql.json';
import postgresql from '@/help/postgresql.json';
import zabbix from '@/help/zabbix.json';loongson
import nodejsRelease from '@/help/nodejs-release.json';
import loongson from '@/help/loongson.json';
import dockerCe from '@/help/docker-ce.json';


export default {
  name: 'List',
  components: {
    SysDialog,
    ToolDialog,
    LanguageDialog,
  },
  data() {
    return {
      radio: 'all',
      showAllList: [],
      showSysList: [],
      showSelfList: [],
      showLanguageList: [],
      showToolList: [],
      dialogSysVisible: false,
      mirrorsSysDetail: {},
      dialogToolVisible: false,
      mirrorsToolDetail: {},
      dialogLanguageVisible: false,
      mirrorsLanguageDetail: {},
      bclinux,
      anolis,
      openeuler,
      centos,
      centosVault,
      elrepo,
      epel,
      fedora,
      opensuse,
      rocky,
      ubuntuReleases,
      ubuntu,
      apache,
      ceph,
      elasticstack,
      jenkins,
      mongodb,
      mysql,
      postgresql,
      zabbix,
      nodejsRelease,
      loongson,
      dockerCe,
    };
  },
  computed: {
    ...mapState([ 'mirrorsList' ]),
    rowAllNum() {
      return Math.ceil(this.showAllList.length / 4);
    },
    rowSysNum() {
      return Math.ceil(this.showSysList.length / 4);
    },
    rowSelfNum() {
      return Math.ceil(this.showSelfList.length / 4);
    },
    rowLanguageNum() {
      return Math.ceil(this.showLanguageList.length / 4);
    },
    rowToolNum() {
      return Math.ceil(this.showToolList.length / 4);
    }
  },
  methods: {
    ...mapActions([ 'getMirrorsList' ]),
    sysList(item) {
      return item.category === 'sys' || item.category === 'self';
    },
    selfList(item) {
      return item.category === 'self';
    },
    languageList(item) {
      return item.category === 'language';
    },
    toolList(item) {
      return item.category === 'tool';
    },
    changeColor(item) {
      if (item.status === 'success') {
        return 'dot dot-success';
      } else if (item.status === 'syncing') {
        return 'dot dot-syncing';
      } else if (item.status === 'failed') {
        return 'dot dot-success';
      }
    },
    changeDes(item) {
        if (item.status === 'success') {
          return '可用';
        } else if (item.status === 'syncing') {
          return '正在同步';
        } else if (item.status === 'failed') {
          return '可用';
        }
    },
    changeTime(item) {
      const arr = item.last_update.split(" ");
      return arr[0] + ' ' + arr[1];
    },

    toChild(name, category){
      let flag = false;
      DETAIL_LIST.forEach((item) => {
        if (item === name.toLowerCase()) {
          flag = true;
        }
      })
      if (flag) {
        if (category === 'sys' || category === 'self') {
          this.mirrorsSysDetail = eval(`this.${this.changeName(name)}`);
          this.dialogSysVisible = true;
        } else if (category === 'tool') {
          this.mirrorsToolDetail = eval(`this.${this.changeName(name)}`);
          this.dialogToolVisible = true;
        } else if (category === 'language') {
          this.mirrorsLanguageDetail = eval(`this.${this.changeName(name)}`);
          this.dialogLanguageVisible = true;
        }
      } else {
        window.open(`https://mirrors.cmecloud.cn/${name}`,'_blank');
      }
    },
    changeVisible(category) {
      if (category === 'sys') {
        this.dialogSysVisible = false;
      } else if (category === 'tool') {
        this.dialogToolVisible = false;
      } else if (category === 'language') {
        this.dialogLanguageVisible = false;
      }
    },
    changeName(name) {
      return name.replace(/-(\w)/g,function($0,$1){
         return $1.toUpperCase();
      });
    }
  },
  mounted () {
    this.getMirrorsList().then(() => {
      this.mirrorsList.forEach((e, i) => {
        if (e.name === 'bclinux') {
          [this.mirrorsList[0], this.mirrorsList[i]] = [this.mirrorsList[i], this.mirrorsList[0]];
        }
      });
      this.showAllList = this.mirrorsList;
      this.showSysList = this.showAllList.filter(this.sysList);
      this.showSelfList = this.showAllList.filter(this.selfList);
      this.showLanguageList = this.showAllList.filter(this.languageList);
      this.showToolList = this.showAllList.filter(this.toolList);
    });
  },
}
</script>

<style lang="scss" scoped>
.content { 
  min-height: 340px;
  ::v-deep .el-row {
    margin-top: 20px;
  }
  .img-logo {
    position: absolute;
    height: 46px;
    width: 46px;
    top: 0px;
    right: 0px;
  }
  .dot{
    width: 6px;
    height: 6px;
    line-height: 30px;
    border-radius: 100%;
    display:inline-block;
  }
  .dot-success{
    background: #10C038;
  }
  .dot-syncing{
    background: #337DFF
  }
  .dot-failed{
    background: #F04134;
  }
  .card-name{
    font-size:20px;
    font-weight: 500;
  }
  .card-status{
    height:30px;
    line-height: 30px;
    margin-left: 8px;
    font-size:14px;
    display:inline-block;
    vertical-align: middle;
    color:#333;
    font-weight: 400;
  }
  .card-update{
    font-size:12px;
    color:#666;
    font-weight: 400;
    height: 20px;
    line-height:20px;
  }
  .rotate-font{
    transform: rotate(45deg);
    position: absolute;
    right: 2px;
    top: 8px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}
</style>
