<template>
    <div class="header">
      <div class="logo">
        <a href="https://ecloud.10086.cn/home/">
          <img src="~@/assets/images/ecloud_logo.png" />
        </a>
      </div>
      <span class="second">
        <a href="mailto:4001100865@cmss.chinamobile.com" target="_blank">
          反馈与建议
        </a>
      </span>
    </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
    };
  },
}
</script>

<style lang="scss" scoped>
.container {
    height: 100%;
}

.header {
    background: #212121;
}

.header .logo {
    margin-left: 45px;
    height: 40px;
}
  
.header .logo img {
    width: 210px;
    position: absolute;
    top: -10px;
    left: 10px;
}
.header span {
    position: absolute;
    color:#fff;
    font-size: 12px;
    line-height: 20px;
    padding: 4px 15px;
    top:5px;
}
.first {
    right: 100px;
}
.second {
    right: 0px;
}

.header span a{
    color:#fff;
    text-align: center;
    cursor: pointer;
}

.header span a:hover{
    text-align: center;
    cursor: pointer;
    color:#337DFF;
}

</style>
