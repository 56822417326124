<template>
  <div class="footer">
    <div class="content">
      <p>Copyright ©2022 中移（苏州）软件技术有限公司 
        <a href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer" target="_blank">
          苏ICP备15037412号
        </a>
      </p>  
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32050502011972" rel="noreferrer" target="_blank">
        <img src="~@/assets/images/record_icon.png" />
        苏公网安备32050502011972号
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
    };
  },
}
</script>

<style lang="scss" scoped>
.footer {
  height: 90px;
  background-color: #333;
  .content {
    padding: 20px;
    text-align: center;
    font-size: 12px;
    color: #ccc;
    line-height: 25px;
    a {
      color: #ccc;
      margin-right: 10px;
      margin-left: 10px;
    }
    a:hover {
      color: #337DFF;
    }
    img {
      vertical-align: middle;
      margin-right: 2px;
    }
  }
}
</style>
